* {
    box-sizing: border-box;
}

body {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.editor-toolbar,
.CodeMirror {
    border: 0 !important;
}

.CodeMirror {
    line-height: 1.5;
}

.editor-toolbar {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: -30px;
    z-index: 100;
    background: white;
}

.editor-statusbar {
    position: sticky !important;
    bottom: -30px;
    z-index: 100;
    background: white;
}
